import { createAction } from "redux-actions"

const p = payload => payload // payloadCreator
const m = (payload, meta) => meta // metaCreator

export const authenticate = createAction("AUTHENTICATE", p, m)
export const unauthenticate = createAction("UNAUTHENTICATE", p, m)
export const bookingsIndex = createAction("BOOKINGS_INDEX", p, m)
export const activitiesIndex = createAction("ACTIVITIES_INDEX", p, m)
export const searchUpdate = createAction("SEARCH_UPDATE", p, m)
export const filtersSearchUpdate = createAction("FILTERS_SEARCH_UPDATE", p, m)
export const currentLocationUpdate = createAction("CURRENT_LOCATION_UPDATE", p, m)
