import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { DEFAULT_APP_LANGUAGE } from "../constants"

import en from "../assets/json/lang/en.json"

const resources = {
  ...en,
}

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: DEFAULT_APP_LANGUAGE,
  resources,
  // Handle rendering while translations are not yet loaded
  react: {
    wait: true,
  },
})

export default i18n
