export const BOOKING_TATTOO = "tattoo"
export const BOOKING_RESIDENT_INVITE = "resident_invite"
export const BOOKING_GUEST_INVITE = "guest_invite"
export const BOOKING_RESIDENT_REQUEST = "resident_request"
export const BOOKING_GUEST_REQUEST = "guest_request"
export const BOOKING_TRAVEL = "travel_post"

export const BOOKING_TYPES = [
  BOOKING_TATTOO,
  BOOKING_RESIDENT_INVITE,
  BOOKING_GUEST_INVITE,
  BOOKING_RESIDENT_REQUEST,
  BOOKING_GUEST_REQUEST,
  BOOKING_TRAVEL,
]

export const BOOKING_STATUS_PENDING = "pending"
export const BOOKING_STATUS_ACCEPTED = "accepted"
export const BOOKING_STATUS_COMPLETED = "completed"
export const BOOKING_STATUS_REJECTED = "rejected"
export const BOOKING_STATUS_CANCELLED = "cancelled"

export const BOOKING_STATUSES = [
  BOOKING_STATUS_PENDING,
  BOOKING_STATUS_ACCEPTED,
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_REJECTED,
  BOOKING_STATUS_CANCELLED,
]

export const BOOKING_PAYMENT_STATUS_INCOMPLETE = "incomplete"
export const BOOKING_PAYMENT_STATUS_PENDING = "pending"
export const BOOKING_PAYMENT_STATUS_PAID = "paid"
export const BOOKING_PAYMENT_STATUS_RECEIVED = "received"
export const BOOKING_PAYMENT_STATUS_FAILED = "failed"

export const BOOKING_PAYMENT_STATUSES = [
  BOOKING_PAYMENT_STATUS_INCOMPLETE,
  BOOKING_PAYMENT_STATUS_PENDING,
  BOOKING_PAYMENT_STATUS_PAID,
  BOOKING_PAYMENT_STATUS_RECEIVED,
  BOOKING_PAYMENT_STATUS_FAILED,
]

// export const BOOKING_ACTION_OTHER = "other"

export const BOOKING_PLACEMENTS = [
  "foot",
  "ankle",
  "leg",
  "thigh",
  "lowerBack",
  "back",
  "chest",
  "arm",
  "sholder",
  "neck",
  "face",
  "other",
  "notSure",
]

export const BOOKING_SIZES = ["small", "medium", "large", "notSure"]

export const BOOKING_BUDGETS = ["cheap", "medium", "expensive"]
