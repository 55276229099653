import t from "@actions/types"

export const initialState = {
  currentUser: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case t.AUTHENTICATE:
      return {
        ...state,
        currentUser: action.payload.currentUser,
      }
    case t.UNAUTHENTICATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
