import t from "@actions/types"

export const initialState = {
  activities: {},
  bookings: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case t.ACTIVITIES_INDEX:
      return {
        ...state,
        activities: action.payload.activities,
      }
    case t.BOOKINGS_INDEX:
      return {
        ...state,
        bookings: action.payload.bookings,
      }
    default:
      return state
  }
}

export default reducer
