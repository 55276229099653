// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-add-your-studio-index-tsx": () => import("./../../../src/pages/add-your-studio/index.tsx" /* webpackChunkName: "component---src-pages-add-your-studio-index-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-artists-index-tsx": () => import("./../../../src/pages/artists/index.tsx" /* webpackChunkName: "component---src-pages-artists-index-tsx" */),
  "component---src-pages-book-a-tattoo-index-tsx": () => import("./../../../src/pages/book-a-tattoo/index.tsx" /* webpackChunkName: "component---src-pages-book-a-tattoo-index-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-the-team-index-tsx": () => import("./../../../src/pages/join-the-team/index.tsx" /* webpackChunkName: "component---src-pages-join-the-team-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-sell-on-iynk-index-tsx": () => import("./../../../src/pages/sell-on-iynk/index.tsx" /* webpackChunkName: "component---src-pages-sell-on-iynk-index-tsx" */),
  "component---src-pages-stats-index-tsx": () => import("./../../../src/pages/stats/index.tsx" /* webpackChunkName: "component---src-pages-stats-index-tsx" */),
  "component---src-pages-studios-index-tsx": () => import("./../../../src/pages/studios/index.tsx" /* webpackChunkName: "component---src-pages-studios-index-tsx" */),
  "component---src-pages-tattoos-index-tsx": () => import("./../../../src/pages/tattoos/index.tsx" /* webpackChunkName: "component---src-pages-tattoos-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/Article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-feature-index-tsx": () => import("./../../../src/templates/Feature/index.tsx" /* webpackChunkName: "component---src-templates-feature-index-tsx" */),
  "component---src-templates-tattoo-index-tsx": () => import("./../../../src/templates/Tattoo/index.tsx" /* webpackChunkName: "component---src-templates-tattoo-index-tsx" */),
  "component---src-templates-user-index-tsx": () => import("./../../../src/templates/User/index.tsx" /* webpackChunkName: "component---src-templates-user-index-tsx" */)
}

