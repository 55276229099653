import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistReducer, persistStore } from "redux-persist"
import { createStore } from "redux"
import storage from "redux-persist/lib/storage"
import rootReducer from "@reducers"
// import { configureStore } from "./config"

const Main = ({ element }) => {
  // const { store, persistor } = configureStore()

  const persistConfig = {
    key: "root",
    storage,
    blacklist: [],
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const store = createStore(persistedReducer)
  const persistor = persistStore(store)

  // Fixing SSR
  // Using `wrapRootElement` makes `gatsby-plugin-react-helmet` not work correctly
  // - https://github.com/gatsbyjs/gatsby/issues/25224
  // The fix is here:
  // - https://stackoverflow.com/questions/58238525/gatsby-not-generating-correct-static-html-files/62501513#62501513
  // Also:
  // - https://stackoverflow.com/questions/61442468/gatsby-react-helmet-generating-empty-title-on-ssr
  // https://github.com/gatsbyjs/gatsby/issues/11761
  // if (typeof window === "undefined") {
  //   return <Provider store={store}>{element}</Provider>
  // }
  // https://github.com/vercel/next.js/issues/8240#issuecomment-647699316

  return (
    <Provider store={store}>
      <PersistGate loading={element} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}

export default Main
