export const USERS_ROLE_CUSTOMER = "customer"
export const USERS_ROLE_ARTIST = "artist"
export const USERS_ROLE_STUDIO = "studio"

export const USERS_ROLES = [
  USERS_ROLE_CUSTOMER,
  USERS_ROLE_ARTIST,
  USERS_ROLE_STUDIO,
]

export const USERS_STATUS_ACTIVE = "active"
export const USERS_STATUS_INACTIVE = "inactive"
export const USERS_STATUS_FROZEN = "frozen"
export const USERS_STATUS_PAID = "paid"
export const USERS_STATUS_INSTAGRAM = "instagram"

export const USERS_VERIFIED = true
export const USERS_UNVERIFIED = false

export const USERS_STATUSES = [
  USERS_STATUS_ACTIVE,
  USERS_STATUS_INACTIVE,
  USERS_STATUS_FROZEN,
  USERS_STATUS_PAID,
  USERS_STATUS_INSTAGRAM,
]
