import "./src/assets/scss/styles.scss"

// Also included in gatsby-ssr and Layout
import "@lib/i18n"

import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

import wrapWithProvider from "@store/browserProvider"
export const wrapRootElement = wrapWithProvider

export const onClientEntry = async () => {
  // https://www.gatsbyjs.com/plugins/gatsby-background-image/
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

// Save the position of the scroll
// By default, Gatsby loads the window from [0,0]
// https://www.gatsbyjs.com/docs/browser-apis/#shouldUpdateScroll
// https://github.com/gatsbyjs/gatsby/issues/5332
// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   // const currentPosition = getSavedScrollPosition(location)

//   // Docs are incorrect?
//   // https://github.com/gatsbyjs/gatsby/issues/23842
//   // const currentPosition = getSavedScrollPosition(location, location.key)
//   const currentPosition = getSavedScrollPosition(location, location.pathname)

//   // https://github.com/gatsbyjs/gatsby/issues/23842#issuecomment-665936677
//   // const getLatestSavedScrollPosition = pathname => {
//   //   let n = sessionStorage.length
//   //   let i = 0
//   //   const partialKey = `@@scroll|${pathname}|`
//   //   let results = []
//   //   while (++i < n) {
//   //     const key = sessionStorage.key(i)
//   //     if (key.includes(partialKey)) {
//   //       results.push(key)
//   //     }
//   //   }
//   //   if (results.length === 0) {
//   //     return 0
//   //   }
//   //   results.sort()
//   //   return sessionStorage.getItem(results[results.length - 1])
//   // }
//   // const currentPosition = getLatestSavedScrollPosition(location.pathname)

//   window.scrollTo(...(currentPosition || [0, 0]))
//   return false
// }

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `This application has been updated. ` +
//     `Reload to display the latest version?`
//   )
//   if (answer === true) {
//     window.location.reload()
//   }
// }
