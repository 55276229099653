export const STYLES = [
  "Abstract",
  "Animals",
  "Asian",
  "Black & White",
  "Black & Grey",
  "Blackwork",
  "Chicano",
  "Classical",
  "Colour",
  "Dark",
  "Dotwork",
  "Eco-friendly",
  "Fantasy",
  "Female-artist",
  "Fine-Line",
  "Geometric",
  "Gothic",
  "Hand-Poked",
  "Ignorant",
  "Illustrative",
  "Japanese",
  "Lettering",
  "LGBTQIA",
  "Minimalism",
  "Neo-Traditional",
  "Nu-School",
  "Ornamental",
  "Other",
  "Polka",
  "Realism",
  "Skull",
  "Surrealism",
  "Traditional",
  "Tribal",
  "Vegan",
  "Watercolor",
]
