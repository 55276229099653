import i18n from "i18next"
const translations = i18n.t.bind(i18n)

import { PHOTO_TATTOO, PHOTO_FLASH } from "@constants"
import t from "@actions/types"

export const initialState = {
  query: "",
  filters: {
    search: {
      // priceMax: C.POSSIBLE_PRICES[C.POSSIBLE_PRICES.length - 1],
      // priceMin: C.POSSIBLE_PRICES[0],
      style: translations("components.Filter.all"),
      type: [PHOTO_TATTOO, PHOTO_FLASH], // translations("components.Filter.all")
    },
  },
  currentLocation: undefined
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case t.SEARCH_UPDATE:
      return {
        ...state,
        query: action.payload.query,
      }
    case t.FILTERS_SEARCH_UPDATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: {
            ...state.filters.search,
            ...action.payload.search,
          },
        },
      }
    case t.CURRENT_LOCATION_UPDATE:
      return {
        ...state,
        currentLocation: action.payload.currentLocation,
      }
    default:
      return state
  }
}

export default reducer
