import { snakeCase } from "lodash"

/**
 * Convert a object with action creators created with
 * redux-actions createAction to a object with these
 * functions names as action types
 *
 * "someAction" becomes "SOME_ACTION"
 *
 * @param  {Object} actions Object with action creators
 * @return {Object}         Object with action types constants
 */

export default actions => {
  const constants: any = {}
  Object.keys(actions).forEach(action => {
    const actionType = actions[action].toString()
    const actionConstant = snakeCase(action).toUpperCase()
    constants[actionConstant] = actionType
  })
  return constants
}
